import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import Intro from "../../components/servicos/intro"
import IntroMobile from "../../components/servicos/introMobile"
import Banner from "../../components/servicos/banner"
import Info from "../../components/servicos/infoHigi"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"
import FormServicos from "../../components/servicos/formServicos"
import FormServicosMobile from "../../components/servicos/formServicosMobile"
import Img from '../../images/servicos/imgs/higiene-oral/higiene-oral-2.jpg'

const HigienePage = ({ data }) => (
  <Layout>
    <SEO title="Higiene Oral" description={data.higieneJson.intro.text} />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <IntroMobile data={data.higieneJson.intro} img={Img} />
        <Banner data={data.higieneJson.banner} alt={data.higieneJson.alt}/>
        <Info data={data.higieneJson.content} />
        <FormServicosMobile nome="Higiene Oral" />
        <FooterMobile data={data.globalJson.footer} />
      </>
    ) : (
      <>
        <Navbar />
        <Intro data={data.higieneJson.intro} img={Img}/>
        <Banner data={data.higieneJson.banner} alt={data.higieneJson.alt}/>
        <Info data={data.higieneJson.content} />
        <FormServicos nome="Higiene Oral" />
        <Footer data={data.globalJson.footer} />
      </>
    )}
  </Layout>
)

export default HigienePage

export const Json = graphql`
  query higiene {
    higieneJson {
      intro {
        title
        text
      }
      banner {
        childImageSharp {
          fluid(quality: 100, maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      alt
      content {
        title
        list
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
